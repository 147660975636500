import React, { useEffect, useRef } from 'react';
import StepperActions from 'components/StepperActions/StepperActions';
import Stepper from 'components/Stepper/Stepper';
import { useSelector, useDispatch } from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import CardsList from 'components/CardsList/CardsList';
import { fetchCampaign, toggleSelect } from 'redux/features/campaign.slice';
import Button from '@material-ui/core/Button';
import ExpansionPanel from 'components/ExpansionPanel/ExpansionPanel';
import nestArrayOfObjects from 'utils/helpers/nestArrayOfObjects';
import Table from 'components/Table/Table';
import { useParams, useHistory } from 'react-router-dom';
import { steps } from 'config/constants';
import AdForm from 'components/AdForm/AdForm';
import useStyles from './styles';

function Campagin() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeStep = useSelector(state => state.activeStep);
  const campaign = useSelector(state => state.campaign);
  const { attachedFiles, fields } = useSelector(state => state.adForm);
  const transitionDuration = 500;
  const toPrintRef = useRef();
  const { slug } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchCampaign(slug, history));
  }, [dispatch, slug, history]);

  return (
    <>
      <Stepper />
      <div className={classes.content}>
        {steps.map((item, i) => {
          const list = campaign.items[item];

          const allSelected = list.every(itm => itm.selected);

          const locationsNested = nestArrayOfObjects(
            campaign.items['Display Locations'],
            'province',
            'areas',
            'area',
            'cities',
          );

          const brandsNested = nestArrayOfObjects(
            campaign.items['Display Brands'],
            'network',
            'brands',
          );

          return (
            <Collapse
              mountOnEnter
              unmountOnExit
              key={item}
              timeout={transitionDuration}
              in={activeStep === i}
            >
              <Typography className={classes.title} variant="h5">
                {`Select ${i < 2 ? 'your' : 'targeted'} ${item.toLowerCase()}`}.
              </Typography>
              {i > 1 && i < 5 && (
                <Button
                  onClick={() => dispatch(toggleSelect(true, item))}
                  color="secondary"
                  className={classes.selectAll}
                >
                  {allSelected ? 'Unselect All' : 'Select All'}
                </Button>
              )}
              {i < 3 && <CardsList wizardItem={item} list={list} />}
              {i === 3 && <ExpansionPanel wizardItem={item} panelList={locationsNested} />}
              {i === 4 && <ExpansionPanel wizardItem={item} panelList={brandsNested} />}
            </Collapse>
          );
        })}
        <Collapse in={activeStep === 5} timeout={transitionDuration} mountOnEnter unmountOnExit>
          <Typography className={classes.title} variant="h5">
            Fill in your information below
          </Typography>
          <AdForm />
        </Collapse>
        <Collapse in={activeStep === 6} timeout={transitionDuration} mountOnEnter unmountOnExit>
          <div ref={toPrintRef}>
            <Typography className={classes.title} variant="h5">
              Campaign Summary
            </Typography>
            <Table items={campaign.items} attachedFiles={attachedFiles} advertiserInfo={fields} />
          </div>
        </Collapse>
      </div>
      <StepperActions slug={slug} toPrintRef={toPrintRef} />
    </>
  );
}

export default Campagin;
