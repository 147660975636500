import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  logoContainer: {
    maxWidth: spacing(50),
    width: '100%',
  },
  logo: {
    maxWidth: '77vw',
    margin: '0 auto',
    display: 'block',
  },
}));
