/**
 * Default theme mixins provided by MUI
 * https://material-ui.com/customization/default-theme/?expand-path=$.mixins
 */

import palette from './palette.theme';

export default {
  scrollbar: {
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    },
    '&::-webkit-scrollbar': {
      width: 6,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.secondary.main,
      borderRadius: 8,
    },
  },
};
