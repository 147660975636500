/**
 * From here, you can change the default props of all the Material-UI components.
 * https://material-ui.com/customization/globals/#default-props
 */

export default {
  // Name of the component ⚛️
  MuiButtonBase: {
    // The properties to apply
    // disableRipple: true, // No more ripple, on the whole application !
  },
};
