import React, { memo } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TvIcon from '@material-ui/icons/Tv';

function SelectedScreens({
  anchorEl,
  setAnchorEl,
  selection,
  totalScreens,
  onSelection,
  disabled,
}) {
  const handleClose = evt => {
    evt.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        disabled={disabled}
        variant="outlined"
        size="small"
        color="secondary"
        onClick={evt => {
          evt.stopPropagation();
          totalScreens > 1 && setAnchorEl(evt.currentTarget);
        }}
        aria-controls="select-number-of-screens"
        endIcon={<TvIcon />}
      >
        {`${selection}/${totalScreens}`}
      </Button>
      <Menu
        id="select-number-of-screens"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: true,
        }}
        PaperProps={{
          style: {
            maxHeight: 250,
            maxWidth: 64,
          },
        }}
      >
        {[...Array(totalScreens)].map((_, i) => {
          const option = i + 1;
          return (
            <MenuItem
              key={option}
              selected={option === selection}
              onClick={evt => {
                handleClose(evt);
                onSelection(option);
              }}
            >
              {option}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default memo(SelectedScreens);
