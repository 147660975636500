import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from 'components/ExpansionPanel/ExpansionPanel';
import nestArrayOfObjects from 'utils/helpers/nestArrayOfObjects';
import FileChip from 'components/FileChip/FileChip';
import useStyles from './styles';

function Table({ items, attachedFiles, advertiserInfo }) {
  const classes = useStyles();
  const firstTwoRows = [items['Campaign Objective'], items['Media Type']].map(list =>
    list.find(listItem => listItem.selected),
  );

  const brands = nestArrayOfObjects(
    items['Display Brands'].filter(listItem => listItem.selected),
    'network',
    'brands',
  );
  const contactInfo = [
    { key: 'Name', value: advertiserInfo.name },
    { key: 'Email', value: advertiserInfo.email },
    { key: 'Contact Number', value: advertiserInfo.contact },
    { key: 'Company', value: advertiserInfo.company },
  ];

  return (
    <TableContainer component={Paper}>
      <MuiTable className={classes.table} aria-label="simple table">
        <TableBody>
          {contactInfo.map(({ key, value }) => (
            <TableRow key={key} className={classes.tableRow}>
              <TableCell variant="head" component="th" scope="row">
                {key}
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.title}>{value}</Typography>
              </TableCell>
            </TableRow>
          ))}

          {firstTwoRows.map((row, i) => (
            <TableRow key={i}>
              <TableCell variant="head" component="th" scope="row">
                {i < 1 ? 'Campaign Objective' : 'Ad Type'}
              </TableCell>
              <TableCell align="center">
                <div className={classes.chip}>
                  <Typography className={classes.title}>
                    <Icon className={classes.icon}>{row.icon}</Icon>
                    <b>{row.title}</b>
                  </Typography>
                </div>
              </TableCell>
            </TableRow>
          ))}
          <TableRow className={classes.tableRow}>
            <TableCell variant="head" component="th" scope="row">
              Attached Files
            </TableCell>
            <TableCell align="center" className={classes.files}>
              {attachedFiles.map((file, index) => (
                <FileChip key={index} file={file} />
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" component="th" scope="row">
              Branches
            </TableCell>
            <TableCell className={classes.brandsContainer} align="center">
              <ExpansionPanel summary panelList={brands} />
            </TableCell>
          </TableRow>
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

Table.propTypes = {
  items: PropTypes.object.isRequired,
};

export default memo(Table);
