import activeStepReducer from './activeStep.slice';
import campaignReducer from './campaign.slice';
import networkReachReducer from './networkReach.slice';
import drawerOpenReducer from './drawerOpen.slice';
import branchesReducer from './branches.slice';
import adFormReducer from './adForm.slice';
import campaignsListReducer from './campaignsList.slice';

const rootReducer = {
  activeStep: activeStepReducer,
  campaign: campaignReducer,
  branches: branchesReducer,
  networkReach: networkReachReducer,
  drawerOpen: drawerOpenReducer,
  adForm: adFormReducer,
  campaignsList: campaignsListReducer,
};

export default rootReducer;
