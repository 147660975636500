import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette }) => ({
  chipLabel: {
    fontWeight: 500,
    '& > *': {
      color: palette.grey.A400,
    },
  },
  chipIcon: {
    color: palette.grey.A400,
  },
  tooltip: {
    backgroundColor: '#333 !important',
  },
  arrow: {
    color: '#111 !important',
  },
  fileSize: {
    fontSize: '1rem',
    marginLeft: spacing(1),
  },
}));
