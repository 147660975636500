import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  root: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: spacing(-8),
  },
  barContainer: {
    marginTop: spacing(7),
    maxWidth: spacing(49),
    width: '100%',
    '& > div': {
      maxWidth: '76vw',
      margin: '0 auto',
    },
  },
}));
