import React, { memo } from 'react';
import CardItem from 'components/CardItem/CardItem';
import PropTypes from 'prop-types';
import useStyles from './styles';

function CardsList({ list, wizardItem }) {
  const classes = useStyles();

  return (
    <div className={classes.cardList}>
      {list.map((item, i) => (
        <CardItem key={item.title} index={i} wizardItem={wizardItem} {...item} />
      ))}
    </div>
  );
}

CardsList.propTypes = {
  list: PropTypes.array.isRequired,
  wizardItem: PropTypes.string.isRequired,
};

export default memo(CardsList);
