import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints }) => ({
  root: {
    [breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  main: {
    padding: spacing(3, 1.5),
    margin: '0 auto',
    maxWidth: spacing(96),
    minHeight: 'calc(100vh - 72px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    [breakpoints.up('sm')]: {
      minHeight: '100vh',
      padding: spacing(3),
    },
    // [breakpoints.up('md')]: {
    // 	minWidth: spacing(86.5)
    // },
    // [breakpoints.up('lg')]: {
    // 	width: spacing(96)
    // }
  },
}));
