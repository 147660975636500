import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints }) => ({
  root: {
    padding: spacing(1, 4, 4),
    [breakpoints.up('sm')]: {
      padding: spacing(2, 4, 4),
    },
    [breakpoints.up('lg')]: {
      padding: spacing(3, 4, 4),
    },
  },
  openDialogBtn: {
    margin: spacing(5, 0, 2),
  },
}));
