import React, { memo, useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { formFields } from 'config/constants';
import { useSelector, useDispatch } from 'react-redux';
import { setForm } from 'redux/features/adForm.slice';
import UploadDialog from 'components/UploadDialog/UploadDialog';
import Chips from 'components/Chips/Chips';
import useStyles from './styles';

function AdForm() {
  const adForm = useSelector(state => state.adForm);
  const [fields, setFields] = useState(adForm.fields);
  const [validation, setValidation] = useState(adForm.validation);
  const [dialogOpen, setDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(setForm({ fields, validation }));
  }, [dispatch, fields, validation]);

  const validate = (name, value) => {
    const re = /\S+@\S+\.\S+/;
    const validEmail = re.test(value);
    switch (name) {
      case 'name':
        return value.length < 1 ? `Enter your name` : '';

      case 'email':
        return value.length < 1
          ? `Enter your email address`
          : !validEmail
          ? 'Please enter a valid email'
          : '';

      case 'contact':
        return value.length < 1
          ? `Enter your contact number`
          : value.length < 8 || value.length > 14
          ? 'Please enter a valid contact number'
          : '';

      case 'company':
        return value.length < 1 ? `Enter ${name}` : '';

      default:
        break;
    }
    return '';
  };

  const handleValidation = evt => {
    const { name, value } = evt.target;
    setValidation(state => ({ ...state, [name]: validate(name, value) }));
  };

  const handleChange = evt => {
    const { name, value } = evt.target;
    setFields(state => ({ ...state, [name]: value }));
    !validate(name, value) && handleValidation(evt);
  };

  return (
    <>
      <UploadDialog open={dialogOpen} setOpen={setDialogOpen} />
      <Paper className={classes.root}>
        {formFields.map((f, i) => {
          return (
            <TextField
              key={i}
              margin="normal"
              id={f.item}
              value={fields[f.item]}
              onChange={handleChange}
              onBlur={handleValidation}
              className={clsx(classes.textField, classes[f.item])}
              label={f.label}
              type={f.type}
              name={f.item}
              fullWidth
              error={validation[f.item].length > 0}
              helperText={validation[f.item]}
            />
          );
        })}
        <Button
          onClick={() => setDialogOpen(true)}
          variant="contained"
          className={classes.openDialogBtn}
          disabled={adForm.attachedFiles.length >= 3}
        >
          Choose Media
        </Button>
        <Chips attachedFiles={adForm.attachedFiles} />
      </Paper>
    </>
  );
}

export default memo(AdForm);
