import React from 'react';
import ReactDOM from 'react-dom';
import initiateSentry from 'config/sentry';
import tagManagerInitialize from 'config/tag-manager';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
initiateSentry();
tagManagerInitialize();
