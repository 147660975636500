import React, { useState, Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from 'components/List/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MuiList from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import useStyles from './styles';

function ExpansionPanel({ panelList, wizardItem, summary }) {
  const [expanded, setExpanded] = useState('panel1');
  const classes = useStyles({ summary });

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {panelList.map((panelItem, i, arr) => {
        return (
          <Fragment key={i}>
            <Accordion
              TransitionProps={{
                mountOnEnter: true,
                unmountOnExit: true,
              }}
              className={classes.expPanel}
              expanded={summary || expanded === `panel${i + 1}`}
              onChange={handleChange(`panel${i + 1}`)}
            >
              <AccordionSummary
                className={classes.expPanelSummary}
                expandIcon={!summary && <ExpandMoreIcon />}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <ListItemIcon className={classes.icon}>
                  <Icon>
                    {panelItem.province ? 'location_on' : panelItem.brands[0].networkIcon}
                  </Icon>
                </ListItemIcon>
                <Typography>
                  {panelItem.province ? panelItem.province : panelItem.network}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.expPanelDetails}>
                <MuiList dense className={classes.list}>
                  {panelItem.areas &&
                    panelItem.areas.map((items, index) => (
                      <Fragment key={index}>
                        {items.area && (
                          <ListItem className={classes.area}>
                            <ListItemText primary={items.area} />
                          </ListItem>
                        )}
                        <List wizardItem={wizardItem} list={items.cities} />
                      </Fragment>
                    ))}
                  {panelItem.brands && (
                    <List summary={summary} wizardItem={wizardItem} list={panelItem.brands} />
                  )}
                </MuiList>
              </AccordionDetails>
            </Accordion>
            {i < arr.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </div>
  );
}

ExpansionPanel.propTypes = {
  panelList: PropTypes.array.isRequired,
  wizardItem: PropTypes.string,
  summary: PropTypes.bool,
};

export default memo(ExpansionPanel);
