import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  table: {
    '& .MuiTableCell-head': {
      borderRight: `1px solid ${palette.divider}`,
      backgroundColor: 'rgba(0, 0, 0, .03)',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  files: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& .MuiChip-root': {
      margin: spacing(0.5),
    },
  },
  tableRow: {
    '& .MuiTableCell-root': {
      padding: spacing(1.5, 2),
    },
  },
  icon: {
    marginRight: spacing(1),
    fontSize: '1.9rem',
    [breakpoints.up('sm')]: {
      fontSize: '2.2rem',
    },
    [breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  },
  chip: {
    backgroundColor: 'rgba(0, 0, 0, .05)',
    display: 'inline-flex',
    padding: spacing(0, 1.5),
    height: spacing(4.5),
    borderRadius: spacing(2.25),
    '& span': {
      color: '#000',
    },
  },
  brandsContainer: {
    padding: '0 !important',
    borderLeft: `1px solid ${palette.divider}`,
    '& > div': {
      borderRadius: 'unset',
      boxShadow: 'none',
    },
    '& *': {
      cursor: 'default !important',
    },
  },
}));
