import { createAction, createReducer } from '@reduxjs/toolkit';

export const toggleDrawer = createAction('drawerOpen/toggleDrawer');

export default createReducer(true, {
  [toggleDrawer]: (state, action) => {
    if (action.payload === 'open') {
      return true;
    }
    if (action.payload === 'close') {
      return false;
    }
    return !state;
  },
});
