import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  listItemText: {
    '& *': {
      fontWeight: 500,
    },
  },
  secondary: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  location: {
    marginRight: spacing(0.75),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1.4rem',
      marginRight: 4,
    },
  },
  screens: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1.4rem',
    },
  },
  multiply: {
    fontSize: '1.1rem',
    marginLeft: spacing(0.15),
    marginRight: spacing(0.4),
  },
  listItem: {
    '& > div': {
      margin: props => (props.summary ? spacing(0.25, 0) : spacing(0.75, 0)),
    },
  },
}));
