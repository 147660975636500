import { createAction, createReducer } from '@reduxjs/toolkit';
import { adsPlayedPerDay, visitorsPerDay } from 'config/constants';

/**
 * Actions creators
 */
export const setReach = createAction('networkReach/setReach');

/**
 * Initial State
 */
const initialState = {
  loading: true,
  items: [
    { start: 0, end: 0 },
    { start: 0, end: 0 },
    { start: 0, end: 0 },
  ],
};

/**
 * Reducer
 */
export default createReducer(initialState, {
  [setReach]: (state, action) => {
    const { screens, numOfbranches } = action.payload;

    const loops = screens * 30 * adsPlayedPerDay;
    const visitors = numOfbranches * 30 * visitorsPerDay;

    return {
      loading: false,
      items: [
        { start: state.items[0].end, end: visitors },
        { start: state.items[1].end, end: loops },
        { start: state.items[2].end, end: screens },
      ],
    };
  },
});

/**
 * Thunks
 */
export function calcReach(selected, filteredBranches) {
  return function (dispatch, getState) {
    if (selected && filteredBranches) {
      // if user selects any options from the 3rd, 4th or 5th panel

      // number of branches
      const numOfbranches = filteredBranches.length;

      // get the number of screens
      const screens = selected.reduce(
        (acc, curr) => acc + (curr.selectedScreens || curr.screens),
        0,
      );

      dispatch(setReach({ screens, numOfbranches }));
    } else {
      // else the user didn't select any options from the 3rd, 4th or 5th panel
      const { branches } = getState();
      const screens = branches.reduce(
        (acc, curr) => acc + (curr.selectedScreens || curr.screens),
        0,
      );

      const numOfbranches = branches.length;
      dispatch(setReach({ screens, numOfbranches }));
    }
  };
}
