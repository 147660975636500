import React, { memo } from 'react';
import PropTypes from 'prop-types';

import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import Logo from 'components/Logo/Logo';
import useStyles from './styles';

function MainLoader({ show = false, noExit }) {
  const classes = useStyles();

  return (
    <Fade mountOnEnter unmountOnExit in={show} timeout={{ enter: 300, exit: noExit ? 0 : 500 }}>
      <div className={classes.root}>
        <Logo />
        <div className={classes.barContainer}>
          <LinearProgress />
        </div>
      </div>
    </Fade>
  );
}

MainLoader.propTypes = {
  show: PropTypes.bool,
  noExit: PropTypes.bool,
};

export default memo(MainLoader);
