import React, { memo, useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import { uploadToStorage } from 'redux/features/adForm.slice';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDropzone } from 'react-dropzone';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import PublishIcon from '@material-ui/icons/Publish';
import Typography from '@material-ui/core/Typography';
import Chips from 'components/Chips/Chips';
import Grow from '@material-ui/core/Grow';
import { useSnackbar } from 'notistack';
import UploadSnackbar from 'components/UploadSnackbar/UploadSnackbar';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

function UploadDialog({ open, setOpen }) {
  const dispatch = useDispatch();
  const adForm = useSelector(state => state.adForm);
  const attachedFilesLength = adForm.attachedFiles.length;
  const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });
  const [error, setError] = useState('');
  const [attachedFiles, setAttachedFiles] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const rejectedError = 'Only images, videos and html files are allowed!';

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        setError(rejectedError);
      } else if (acceptedFiles.length + attachedFiles.length + attachedFilesLength > 3) {
        setError('You can only select up to 3 files!');
      } else {
        setError('');
        // var vid = document.createElement('video');
        // var fileURL = URL.createObjectURL(acceptedFiles[0]);
        // vid.src = fileURL;
        // console.log(vid);
        // vid.ondurationchange = function (e) {
        // console.log(e);
        // console.log(this.duration);
        // };
        // console.log(acceptedFiles[0]);
        setAttachedFiles(st => [
          ...st.filter(f => !acceptedFiles.map(file => file.name).includes(f.name)),
          ...acceptedFiles,
        ]);
      }
    },
    [setAttachedFiles, attachedFilesLength, attachedFiles.length],
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*, video/*, text/html',
    onDrop,
  });

  const classes = useStyles({ isDragActive, isDragAccept, isDragReject });

  const openSnackbar = () => {
    const key = 'upload-progress';
    const snackbarOptions = {
      persist: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      key,
      content: <UploadSnackbar id={key} />,
    };
    enqueueSnackbar('', snackbarOptions);
  };

  const handleSubmit = () => {
    setOpen(false);
    dispatch(uploadToStorage(attachedFiles, openSnackbar, closeSnackbar));
    setAttachedFiles([]);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
    setAttachedFiles([]);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullScreen={!up600}
      classes={{ paper: classes.dialog }}
      aria-labelledby="select-files"
    >
      <DialogTitle id="select-files">Select files</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dragDropContainer}>
          <div {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} />
            <PublishIcon />

            <Typography className={classes.dropzoneText}>
              {isDragReject
                ? rejectedError
                : isDragActive
                ? 'Drop the files here ...'
                : up600
                ? "Drag 'n' drop some files here, or click to select files"
                : 'Tap to select files'}
            </Typography>
          </div>
          <Collapse in={!!error}>
            <Alert severity="error" onClose={() => setError('')}>
              {error}
            </Alert>
          </Collapse>
          <Chips attachedFiles={attachedFiles} setAttachedFiles={setAttachedFiles} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!attachedFiles.length}
          variant="contained"
          color="primary"
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(UploadDialog);
