/**
 * From here, you can change every single style injected by Material-UI in an Mui component
 * https://material-ui.com/customization/globals/#css
 */

export default {
  // Style sheet name ⚛️
  MuiButton: {
    // Name of the rule
    label: {
      // Some CSS
      // textTransform: 'none',
    },
  },
};
