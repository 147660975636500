import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TvIcon from '@material-ui/icons/Tv';
import nestArrayOfObjects from 'utils/helpers/nestArrayOfObjects';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import useLazyLoading from 'utils/hooks/useLazyLoading';
import ListItem from 'components/ListItem/ListItem';
import useStyles from './styles';

function List({ list, wizardItem, summary }) {
  const classes = useStyles({ summary });
  const summaryList = nestArrayOfObjects(list, 'brand', 'branches');
  const { lastElementRef, itemsCount } = useLazyLoading(false, 5, 1);

  const renderSecondary = (item, key) => (
    <span className={classes.secondary} key={key}>
      <span className={classes.location}>
        {key + 1 ? <LocationOnIcon /> : null}
        {`${item.area ? item.area : item.province}, ${item.city},${
          item.branch ? ` ${item.branch},` : ''
        }`}
      </span>
      <span className={classes.screens}>
        <span>{item.screens}</span>
        <span className={classes.multiply}>x</span>
        <TvIcon />
      </span>
    </span>
  );

  return !summary
    ? list.slice(0, itemsCount).map((item, i, arr) => (
        <Fragment key={i}>
          <ListItem item={item} wizardItem={wizardItem} />
          <div ref={i === arr.length - 1 ? lastElementRef : null} />
        </Fragment>
      ))
    : summaryList.slice(0, itemsCount).map((item, i, arr) => (
        <MuiListItem
          className={classes.listItem}
          key={i}
          ref={i === arr.length - 1 ? lastElementRef : null}
        >
          <ListItemText
            className={classes.listItemText}
            primary={item.brand}
            secondary={item.branches.map((br, index) => renderSecondary(br, index))}
          />
        </MuiListItem>
      ));
}

List.propTypes = {
  list: PropTypes.array.isRequired,
  wizardItem: PropTypes.string,
  summary: PropTypes.bool,
};

export default memo(List);
