import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 320;

export default makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& > div': {
      boxShadow: theme.shadows[4],
      backgroundColor: theme.palette.primary.main,
      overflow: 'visible',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(11) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(13) + 1,
    },
  },
  chevron: {
    position: 'absolute !important',
    top: '47%',
    left: -11,
    zIndex: 4000,
    width: '26px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    '& button:hover': {
      backgroundColor: `initial !important`,
    },
    '& svg': {
      color: '#fff',
      transition: '.4s transform',
      transform: props => (props.drawerOpen ? `rotate(0)` : `rotate(180deg)`),
    },
  },
}));
