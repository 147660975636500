/**
|--------------------------------------------------
| Function, filters the duplicate objects in an array of objects.
| @param1 {Array} Array of objects
| @param2 {String} object key that will be summed
| @param3 {String} object key that the object will be filtered by
| @param4 (optional) {Array} Additional object keys that the object can be filtered by
| @The rest of paramters {String} object keys that will remain in the object
|--------------------------------------------------
*/

export default function reduceArrOfObjValues(array, objKey1, objKey2, extraKeys, ...params) {
  // console.log(extraKeys);
  const objKey3 = extraKeys ? extraKeys[0] : false;
  const objKey4 = extraKeys ? extraKeys[1] : false;
  // reduce object values in an array of objects
  const arrayOfObjects = array
    .reduce((acc, val) => {
      const o = acc
        .filter(obj => {
          return (
            obj[objKey2] === val[objKey2] &&
            obj[objKey3] === val[objKey3] &&
            obj[objKey4] === val[objKey4]
          );
        })
        .pop() || {
        [objKey1]: 0,
        [objKey2]: val[objKey2],
        [objKey3]: val[objKey3],
        [objKey4]: val[objKey4],
        [params[0]]: val[params[0]],
        [params[1]]: val[params[1]],
        [params[2]]: val[params[2]],
        [params[3]]: val[params[3]],
        [params[4]]: val[params[4]],
        [params[5]]: val[params[5]],
        [params[6]]: val[params[6]],
      };

      o[objKey1] += val[objKey1];
      acc.push(o);
      return acc;
    }, [])
    // remove duplicate objects
    .filter((val, i, arr) => arr.indexOf(val) === i);

  // remove object properties that its keys are undefined or false
  return arrayOfObjects.map(object => {
    const allowed = Object.keys(object).filter(key => key !== 'false' && key !== 'undefined');

    return Object.keys(object)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: object[key],
        };
      }, {});
  });
}
