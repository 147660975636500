import React from 'react';
import Drawer from 'components/Drawer/Drawer';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import { useSelector } from 'react-redux';
import MainLoader from 'components/MainLoader/MainLoader';
import { useLocation } from 'react-router-dom';
import useStyles from './styles';

function Transition({ children, type, ...props }) {
  switch (type) {
    case 'slide':
      return <Slide {...props}>{children}</Slide>;
    case 'fade':
      return <Fade {...props}>{children}</Fade>;
    default:
      return <Fade {...props}>{children}</Fade>;
  }
}
function SplitPane({ main, sub }) {
  const campaign = useSelector(state => state.campaign);
  const up600 = useMediaQuery('(min-width:600px)');
  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      <MainLoader noExit={location.from === '/'} show={campaign.loading} />
      <Transition
        direction="left"
        type={location.from === '/' ? 'slide' : 'fade'}
        in={!campaign.loading}
        timeout={600}
      >
        <div>
          {!up600 && <AppBar position="sticky">{sub}</AppBar>}
          <div className={classes.root}>
            <div className={classes.main}>{main}</div>
            {up600 && <Drawer>{sub}</Drawer>}
          </div>
        </div>
      </Transition>
    </>
  );
}

SplitPane.propTypes = {
  main: PropTypes.element.isRequired,
  sub: PropTypes.element.isRequired,
};

export default SplitPane;
