import { createAction, createReducer } from '@reduxjs/toolkit';

export const nextStep = createAction('activeStep/nextStep');
export const previousStep = createAction('activeStep/previousStep');
export const setStep = createAction('activeStep/setStep');

const activeStepReducer = createReducer(0, {
  [nextStep]: state => (state < 6 ? state + 1 : 0),
  [previousStep]: state => (state > 0 ? state - 1 : 0),
  [setStep]: (state, action) => action.payload,
});

export default activeStepReducer;
