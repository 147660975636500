import axios from 'axios';

const url = process.env.REACT_APP_API;
const restURL = process.env.REACT_APP_REST_API;

export default async function ({ query, variables, resource }) {
  if (query) {
    return axios.post(url, {
      query,
      variables,
    });
  }

  return axios.get(`${restURL}${resource}`);
}
