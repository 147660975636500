import React, { memo } from 'react';
import logo from 'assets/adzily-logo.svg';
import useStyles from './styles';

function Logo() {
  const classes = useStyles();

  return (
    <div className={classes.logoContainer}>
      <img src={logo} className={classes.logo} alt="Adzily-logo" />
    </div>
  );
}

export default memo(Logo);
