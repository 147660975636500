import { createAction, createReducer } from '@reduxjs/toolkit';

/**
 * Actions creators
 */
const receivedCampaigns = createAction('campaignsList/receivedCampaigns');

/**
 * Initial State
 */
const initialState = {
  loading: true,
  campaigns: [],
};

/**
 * Reducer
 */
export default createReducer(initialState, {
  [receivedCampaigns]: (state, action) => {
    const campaigns = action.payload;
    return {
      ...state,
      loading: false,
      campaigns,
    };
  },
});

export function fetchCampaigns() {
  return async function (dispatch, getState, { campaignsRef, snackbar }) {
    try {
      const campaigns = [];
      const snapshot = await campaignsRef.orderBy('creationDate', 'desc').get();
      snapshot.forEach(doc => {
        campaigns.push({
          id: doc.id,
          ...doc.data().info.fields,
          date: doc.data().creationDate.seconds,
        });
      });

      dispatch(receivedCampaigns(campaigns));
    } catch (error) {
      snackbar.error('Oops! Something went wrong');
      console.log(error);
    }
  };
}
