import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    position: 'relative',
    backgroundColor: palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    height: spacing(9),
    [breakpoints.up('sm')]: {
      height: '100%',
      padding: props => (props.drawerOpen ? spacing(3, 2.2, 3, 3) : spacing(3, 0, 3)),
      flexDirection: 'column',
      overflowY: 'auto',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: 8,
    },
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.primary.dark,
      borderRadius: 8,
    },
  },
  paper: {
    margin: spacing(2, 0),
  },
  icon: {
    minWidth: '40px !important',
    '& svg': {
      color: palette.text.primary,
    },
  },
  title: {
    '& span': {
      fontSize: '1.3rem',
      fontWeight: 500,
    },
  },
  count: {
    textAlign: 'right',
    '& span': {
      fontWeight: 500,
    },
  },
  countNum: {
    fontSize: '1.35rem',
    [breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
    [breakpoints.up('md')]: {
      fontSize: '1.7rem',
    },
  },
  item: {
    color: '#fff',
    flex: 1,
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'center !important',
    padding: '8px 4px 6px !important',
    [breakpoints.up('sm')]: {
      flexDirection: 'column',
      padding: '8px 0 0 4px !important',
    },
  },
  text: {
    flex: '0 1 auto !important',
    '& > *': {
      fontWeight: 500,
      [breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
    },
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
    '& svg': {
      [breakpoints.down('xs')]: {
        marginRight: spacing(0.75),
        fontSize: '2rem',
      },
    },
  },
}));
