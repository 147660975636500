import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import SplitPane from 'containers/SplitPane/SplitPane';
import Campagin from 'containers/Campagin/Campaign';
import NetworkReach from 'containers/NetworkReach/NetworkReach';
import CampaignsList from 'containers/CampaignsList/CampaignsList';
// import WelcomePage from 'containers/WelcomePage/WelcomePage';

function Routes() {
  return (
    <Router>
      <Switch>
        {/* to load a campaign, a user must enters a valid campaignID that consists of 20 chars, otherwise redirect to homepage "/" */}
        <Route path="/campaign/:slug([a-zA-Z0-9]{20})">
          <SplitPane main={<Campagin />} sub={<NetworkReach />} />
        </Route>
        <Route exact path="/campaign">
          <SplitPane main={<Campagin />} sub={<NetworkReach />} />
        </Route>
        <Route exact path="/campaigns-list">
          <CampaignsList />
        </Route>
        {/* <Route exact path="/">
          <WelcomePage />
        </Route> */}
        <Redirect to="/campaign" />
      </Switch>
    </Router>
  );
}

export default Routes;
