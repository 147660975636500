import React, { memo, useEffect } from 'react';
import MuiDrawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useSelector, useDispatch } from 'react-redux';
import { toggleDrawer } from 'redux/features/drawerOpen.slice';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './styles';

function Drawer({ children }) {
  const dispatch = useDispatch();
  const drawerOpen = useSelector(state => state.drawerOpen);
  const classes = useStyles({ drawerOpen });

  const up900 = useMediaQuery('(min-width:900px)', { noSsr: true });

  useEffect(() => {
    up900 ? dispatch(toggleDrawer('open')) : dispatch(toggleDrawer('close'));
  }, [up900, dispatch]);

  return (
    <MuiDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}
      open={drawerOpen}
      anchor="right"
    >
      <Avatar className={classes.chevron}>
        <IconButton disableRipple onClick={() => dispatch(toggleDrawer())}>
          <ChevronRightIcon />
        </IconButton>
      </Avatar>
      {children}
    </MuiDrawer>
  );
}

export default memo(Drawer);
