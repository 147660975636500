import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tooltip from 'components/Tooltip/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './styles';

function SharePopover({ anchorEl, setAnchorEl, copyToClipboard, link }) {
  const open = !!anchorEl;
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });

  useEffect(() => {
    !anchorEl && setTooltipOpen(false);
  }, [anchorEl]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      classes={{ paper: classes.popover }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <TextField
        value={link}
        inputProps={{
          readOnly: true,
        }}
        variant="outlined"
        className={classes.textField}
        onClick={evt => evt.target.select()}
        onFocus={evt => evt.target.select()}
        onBlur={evt => evt.target.select()}
        autoFocus
      />
      <Tooltip open={tooltipOpen} setOpen={setTooltipOpen} title="Copied!">
        <Button
          color="primary"
          variant="contained"
          startIcon={<AssignmentIcon />}
          onClick={() => {
            copyToClipboard(link);
            setTooltipOpen(true);
          }}
        >
          {up600 ? 'Copy Link' : 'Copy'}
        </Button>
      </Tooltip>
    </Popover>
  );
}

SharePopover.propTypes = {
  setAnchorEl: PropTypes.func.isRequired,
  copyToClipboard: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
};

export default memo(SharePopover);
