import { createAction, createReducer } from '@reduxjs/toolkit';
import reduceArrOfObjValues from 'utils/helpers/reduceArrOfObjValues';
import { calcReach } from 'redux/features/networkReach.slice';
import { receivedBranches } from './branches.slice';
import { setStep } from './activeStep.slice';
import { fetchInfo } from './adForm.slice';

/**
 * Actions creators
 */
const receivedCampaign = createAction('campaign/receivedCampaign');
const selectItem = createAction('campaign/selectItem');
const selectAll = createAction('campaign/selectAll');
const setWizardItems = createAction('campaign/setWizardItems');
const setSelectedScreens = createAction('campaign/setSelectedScreens');

/**
 * Initial State
 */
const initialState = {
  loading: true,
  campaignID: '',
  items: {
    'Campaign Objective': [
      { title: 'Awareness', selected: true, icon: 'favorite', id: 1 },
      { title: 'Conversion', selected: false, icon: 'shopping_basket', id: 2 },
    ],
    'Media Type': [
      { title: 'Image', selected: true, icon: 'image', id: 1 },
      { title: 'Video', selected: false, icon: 'ondemand_video', id: 2 },
      { title: 'HTML', selected: false, icon: 'code', id: 3 },
    ],
    'Display Networks': [],
    'Display Locations': [],
    'Display Brands': [],
  },
};

/**
 * Reducer
 */
export default createReducer(initialState, {
  [receivedCampaign]: (state, action) => {
    const { items, campaignID } = action.payload;
    return {
      ...state,
      loading: false,
      campaignID,
      items,
    };
  },
  [setWizardItems]: (state, action) => {
    const { wizardItem, newBrands, newLocations } = action.payload;

    const newItems =
      wizardItem === 'Display Networks'
        ? { 'Display Locations': newLocations, 'Display Brands': newBrands }
        : { 'Display Brands': newBrands };

    return {
      ...state,
      items: {
        ...state.items,
        ...newItems,
      },
    };
  },
  [selectItem]: (state, action) => {
    const { wizardItem, id } = action.payload;

    const isFirstTwoPanels = wizardItem === 'Campaign Objective' || wizardItem === 'Media Type';

    return {
      ...state,
      items: {
        ...state.items,
        [wizardItem]: state.items[wizardItem].map(card =>
          card.id === id
            ? isFirstTwoPanels
              ? { ...card, selected: true }
              : { ...card, selected: !card.selected }
            : isFirstTwoPanels
            ? { ...card, selected: false }
            : card,
        ),
      },
    };
  },
  [selectAll]: (state, action) => {
    const { wizardItem } = action.payload;
    const allSelected = state.items[wizardItem].every(item => item.selected);

    return {
      ...state,
      items: {
        ...state.items,
        [wizardItem]: state.items[wizardItem].map(item => ({
          ...item,
          selected: !allSelected,
        })),
      },
    };
  },
  [setSelectedScreens]: (state, action) => {
    const { id, selectedScreens } = action.payload;

    return {
      ...state,
      items: {
        ...state.items,
        'Display Brands': state.items['Display Brands'].map(card =>
          card.id === id ? { ...card, selectedScreens } : card,
        ),
      },
    };
  },
});

/**
 * Thunks
 */
export function submitCampaign(campaignID, snackbarAction, history) {
  return async function (dispatch, getState, { campaignsRef, snackbar }) {
    try {
      const { campaign } = getState();
      const { fields } = getState().adForm;
      const newCampaign = {
        campaign: campaign.items,
        info: { fields },
      };

      if (campaignID) {
        const lastEditDate = new Date();
        await campaignsRef.doc(campaignID).update({ ...newCampaign, lastEditDate });
        snackbar.toast('Campaign successfully updated');
      } else {
        const creationDate = new Date();
        await campaignsRef.doc(campaign.campaignID).set({ ...newCampaign, creationDate });

        history.push(`/campaign/${campaign.campaignID}`);

        const action = snackbarAction(campaign.campaignID);
        snackbar.toast('Campaign successfully submitted', { action });
      }
    } catch (error) {
      snackbar.error('Something went wrong');
      console.log(error);
    }
  };
}

export function toggleSelect(all, wizardItem, id, selectedScreens) {
  return function (dispatch, getState) {
    if (selectedScreens) {
      dispatch(setSelectedScreens({ id, selectedScreens }));
    } else {
      all ? dispatch(selectAll({ wizardItem })) : dispatch(selectItem({ wizardItem, id }));
    }
    const isFirstTwoPanels = wizardItem === 'Campaign Objective' || wizardItem === 'Media Type';
    const { items } = getState().campaign;

    if (!isFirstTwoPanels) {
      const selected = items[wizardItem].filter(item => item.selected);
      const selectedNetworks = items['Display Networks'].filter(item => item.selected);
      const filteredBranches =
        wizardItem === 'Display Brands'
          ? selected
          : getState().branches.filter(br => {
              return wizardItem === 'Display Networks'
                ? selected.map(item => item.title).includes(br.network)
                : selected.map(item => item.city).includes(br.city) &&
                    selectedNetworks.map(item => item.title).includes(br.network);
            });

      // calculate the reach based on the selected options
      dispatch(calcReach(selected, filteredBranches));

      // Add/Remove options in the next panel based on the selected options in the current panel (if the user is on Display networks or locations only)
      if (wizardItem === 'Display Networks' || wizardItem === 'Display Locations') {
        const newLocations = reduceArrOfObjValues(
          filteredBranches,
          'screens',
          'city',
          false,
          'province',
          'area',
          'selected',
          'id',
        );
        const newBrands = reduceArrOfObjValues(
          filteredBranches,
          'screens',
          'brand',
          ['city', 'branch'],
          'area',
          'selectedScreens',
          'province',
          'network',
          'networkIcon',
          'selected',
          'id',
        );

        dispatch(setWizardItems({ wizardItem, newBrands, newLocations }));
      }
    }
  };
}

const formatBranches = branches => {
  return branches
    .map(item => ({
      brand: item.brand,
      branch: item.branch,
      network: item.network,
      networkIcon: item.networkIcon,
      city: item.area,
      area: item.division,
      province: item.city,
      screens: parseInt(item.screens, 10),
      selectedScreens: parseInt(item.screens, 10),
      id: parseInt(item.id, 10),
      selected: true,
    }))
    .filter(item => item.screens > 0);
};

export function fetchCampaign(campaignID, history) {
  return async function (dispatch, getState, { API, campaignsRef, snackbar }) {
    if (campaignID && campaignID !== 'campaign') {
      try {
        dispatch(setStep(6));
        const resp = await campaignsRef.doc(campaignID).get();
        const { campaign, info } = resp.data();
        dispatch(fetchInfo(info, campaignID));
        dispatch(
          receivedCampaign({
            campaignID,
            items: {
              'Campaign Objective': campaign['Campaign Objective'],
              'Media Type': campaign['Media Type'],
              'Display Networks': campaign['Display Networks'],
              'Display Locations': campaign['Display Locations'],
              'Display Brands': campaign['Display Brands'],
            },
          }),
        );

        const branchesResp = await API({ resource: 'branches' });
        const branches = formatBranches(branchesResp.data.branches);

        dispatch(receivedBranches(branches));
        dispatch(calcReach());
      } catch (error) {
        dispatch(setStep(0));
        snackbar.error('Something went wrong');
        console.log(error);
        history.push('/');
      }
    } else {
      try {
        const resp = await API({
          query: `
						query {
							objectives {
								id
								title
								iconcss
							}
							adtypes {
								id
								title
								iconcss
							}
						}
					`,
        });

        const data = Object.values(resp.data.data).map((arr, arrIndex) => {
          return arr.map((item, i) => ({
            ...item,
            id: parseInt(item.id, 10),
            selected: !!(i === 0 && arrIndex < 2),
            icon: item.iconcss,
          }));
        });

        const branchesResp = await API({ resource: 'branches' });
        const branches = formatBranches(branchesResp.data.branches);

        const networks = reduceArrOfObjValues(
          branches,
          'screens',
          'network',
          false,
          'networkIcon',
          'selected',
          'id',
        ).map(item => ({
          title: item.network,
          icon: item.networkIcon,
          selected: item.selected,
          screens: item.screens,
          id: item.id,
        }));

        const locations = reduceArrOfObjValues(
          branches,
          'screens',
          'city',
          false,
          'province',
          'area',
          'selected',
          'id',
        );

        const brands = reduceArrOfObjValues(
          branches,
          'screens',
          'brand',
          ['city', 'branch'],
          'area',
          'selectedScreens',
          'province',
          'network',
          'networkIcon',
          'selected',
          'id',
        );

        const ref = campaignsRef.doc();

        dispatch(
          receivedCampaign({
            campaignID: ref.id,
            items: {
              'Campaign Objective': data[0],
              'Media Type': data[1],
              'Display Networks': networks,
              'Display Locations': locations,
              'Display Brands': brands,
            },
          }),
        );
        dispatch(receivedBranches(branches));
        dispatch(calcReach());
      } catch (error) {
        snackbar.error('Something went wrong');
        console.log(error);
      }
    }
  };
}
