import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: spacing(1.5, 2),
    flexWrap: 'wrap',
    marginBottom: props => (props.anUploadIsRunning ? spacing(7) : 0),
    [breakpoints.up('sm')]: {
      marginBottom: 0,
      padding: spacing(2.5, 2),
    },
  },
  backButton: {
    marginRight: `16px !important`,
    order: props => (props.summary ? 1 : 0),
    [breakpoints.up('sm')]: {
      order: 0,
    },
  },
  printButton: {
    order: 2,
    [breakpoints.up('sm')]: {
      marginRight: `16px !important`,
      order: 1,
    },
  },
  uploadBtnContainer: {
    order: 0,
    margin: spacing(0, 0, 2),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.up('sm')]: {
      order: 2,
      width: 'unset',
      display: 'inline-flex',
      margin: 0,
    },
    '& > button': {
      flexBasis: spacing(33.5),
      [breakpoints.up('sm')]: {
        flexBasis: 'unset',
      },
    },
  },
  popover: {
    [breakpoints.up('sm')]: {
      width: '80vw',
      maxWidth: '600px !important',
    },
    // maxWidth: 550,
    padding: spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& button,& input': {
      height: spacing(5),
    },
  },
  textField: {
    flex: 1,
    marginRight: '8px !important',
    '& input': {
      boxSizing: 'border-box',
      padding: spacing(1, 1.5),
      fontWeight: 300,
      color: palette.primary.main,
    },
    '& input::selection': {
      backgroundColor: palette.primary.main,
      color: '#fff',
    },
    '& fieldset': {
      borderColor: palette.primary.main,
    },
  },
}));
