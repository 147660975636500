import { createAction, createReducer } from '@reduxjs/toolkit';
import { formFields } from 'config/constants';

/**
 * Actions creators
 */
const recievedInfo = createAction('adForm/recievedInfo');
export const setForm = createAction('adForm/setForm');
const setAttachedFilesInfo = createAction('adForm/setAttachedFilesInfo');
const setProgress = createAction('adForm/setProgress');
const setSingleFile = createAction('adForm/setSingleFile');
const clearAttachedFiles = createAction('adForm/clearAttachedFiles');
const setUploadTask = createAction('adForm/setUploadTask');
const clearUploadTasks = createAction('adForm/clearUploadTasks');
const deleteFileFromStore = createAction('adForm/deleteFileFromStore');

/**
 * Initial State
 */
const initFields = formFields.reduce((ac, f) => ({ ...ac, [f.item]: '' }), {});

const initialState = {
  fields: initFields,
  validation: initFields,
  attachedFiles: [],
  attachedFilesInfo: [],
  uploadTasks: [],
  newForm: true,
};

/**
 * Reducer
 */
export default createReducer(initialState, {
  [recievedInfo]: (state, action) => {
    const { fields } = action.payload;
    return {
      ...state,
      fields,
      newForm: false,
    };
  },
  [setForm]: (state, action) => {
    const { fields, validation } = action.payload;
    return {
      ...state,
      fields,
      validation,
    };
  },
  [setProgress]: (state, action) => {
    const { index, progress, uploadSt } = action.payload;
    const uploadState = progress === 100 ? 'success' : uploadSt;
    return {
      ...state,
      attachedFilesInfo: state.attachedFilesInfo.map((f, i) =>
        i === index ? { ...f, progress, uploadState } : f,
      ),
    };
  },
  [setSingleFile]: (state, action) => {
    const file = action.payload;

    return {
      ...state,
      attachedFiles: [...state.attachedFiles.filter(f => f.name !== file.name), file],
    };
  },
  [clearAttachedFiles]: state => ({
    ...state,
    attachedFiles: [],
  }),
  [setAttachedFilesInfo]: (state, action) => ({
    ...state,
    attachedFilesInfo: action.payload,
  }),
  [setUploadTask]: (state, action) => ({
    ...state,
    uploadTasks: [...state.uploadTasks, action.payload],
  }),
  [clearUploadTasks]: state => ({
    ...state,
    uploadTasks: [],
  }),
  [deleteFileFromStore]: (state, action) => {
    const file = action.payload;
    return {
      ...state,
      attachedFiles: state.attachedFiles.filter(f => f.fileID !== file.fileID),
    };
  },
});

/**
 * Helper fns
 */
const fetchFile = async ref => {
  try {
    const { name, size, timeCreated, updated, contentType, generation } = await ref.getMetadata();
    const downloadURL = await ref.getDownloadURL();

    return {
      name,
      size,
      timeCreated,
      updated,
      type: contentType,
      fileID: generation,
      downloadURL,
    };
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Thunks
 */
export function uploadToStorage(attachedFiles, openSnackbar, closeSnackbar) {
  return async function (dispatch, getState, { storageRef, snackbar }) {
    const { campaignID } = getState().campaign;

    dispatch(clearUploadTasks());
    dispatch(
      setAttachedFilesInfo(
        attachedFiles.map(f => ({
          name: f.name,
          size: f.size,
          type: f.type,
          progress: 0,
          uploadState: '',
        })),
      ),
    );

    openSnackbar();

    await Promise.all(
      attachedFiles.map((file, index) => {
        return new Promise(function (resolve) {
          const uploadTask = storageRef.child(`${campaignID}/${file.name}`).put(file);
          dispatch(setUploadTask(uploadTask));
          uploadTask.on(
            'state_changed',
            snapshot => {
              const progress = Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              // console.log('Upload is ' + progress + '% done');
              // console.log(snapshot);
              dispatch(setProgress({ index, progress, uploadSt: snapshot.state }));
            },
            error => {
              // Handle unsuccessful uploads
              if (error.code !== 'storage/canceled') {
                console.log(error);
                snackbar.error('Oops, we were unable to upload your file');
              }
            },
            () => {
              // Handle successful uploads on complete
              fetchFile(uploadTask.snapshot.ref).then(fetchedFile => {
                dispatch(setSingleFile(fetchedFile));
              });
              resolve();
            },
          );
        });
      }),
    );

    setTimeout(() => {
      closeSnackbar('upload-progress');
    }, 7000);
  };
}

export function cancelUploads() {
  return async function (dispatch, getState, { snackbar }) {
    const { uploadTasks } = getState().adForm;

    if (uploadTasks.length) {
      await Promise.all(
        uploadTasks.map((task, index) => {
          return new Promise(function (resolve) {
            try {
              getState().adForm.attachedFilesInfo[index].uploadState === 'running' && task.cancel();
              resolve();
            } catch (error) {
              console.log(error);
            }
          });
        }),
      );
      const canceled = uploadTasks.filter(task => task.snapshot.state !== 'success').length;
      !!canceled && snackbar.toast(`${canceled} upload${canceled > 1 ? 's' : ''} canceled`);
    }
  };
}

export function fetchInfo(info, campaignID) {
  return function (dispatch, getState, { storageRef }) {
    dispatch(recievedInfo(info));
    storageRef
      .child(campaignID)
      .listAll()
      .then(res => {
        dispatch(clearAttachedFiles());
        res.items.forEach(itemRef => {
          fetchFile(itemRef).then(fetchedFile => {
            dispatch(setSingleFile(fetchedFile));
          });
        });
      })
      .catch(error => {
        console.log(error);
        // Uh-oh, an error occurred!
      });
  };
}

export function deleteFile(file) {
  return async function (dispatch, getState, { snackbar, storageRef }) {
    try {
      const { campaignID } = getState().campaign;
      const fileRef = storageRef.child(`${campaignID}/${file.name}`);
      await fileRef.delete();

      dispatch(deleteFileFromStore(file));
      snackbar.toast('File deleted successfully');
    } catch (error) {
      console.log(error);
      snackbar.error('We were unable to delete selected file, please try again');
    }
  };
}
