import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints }) => ({
  root: {
    backgroundColor: 'transparent !important',
    padding: '20px 0 !important',
    [breakpoints.up('sm')]: {
      padding: '24px 0 !important',
    },
  },
}));
