import React, { memo } from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import useStyles from './styles';

function Tooltip({ title, open, setOpen, children }) {
  const classes = useStyles();

  return (
    <MuiTooltip
      onClose={() => setOpen(false)}
      classes={{
        tooltipArrow: classes.tooltip,
        arrow: classes.arrow,
      }}
      open={open}
      leaveDelay={1000}
      title={title}
      placement="top"
      arrow
    >
      {children}
    </MuiTooltip>
  );
}

export default memo(Tooltip);
