import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, palette, mixins }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(4, 1.5),
    margin: '0 auto',
    maxWidth: 1060,
    [breakpoints.up('xl')]: {
      maxWidth: '80%',
    },
    [breakpoints.up('sm')]: {
      padding: spacing(4, 3),
    },
  },
  paper: {
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    height: 'calc(90vh - 64px)',
    ...mixins.scrollbar,
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: palette.primary.main,
      color: '#fff',
    },
  },
}));
