import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  tooltip: {
    backgroundColor: '#333 !important',
    padding: '8px 16px !important',
    fontSize: '1.4rem !important',
    fontWeight: '400 !important',
    lineHeight: '1.14286em !important',
  },
  arrow: {
    color: '#111 !important',
  },
});
