import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Grow';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCampaigns } from 'redux/features/campaignsList.slice';
import MainLoader from 'components/MainLoader/MainLoader';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import moment from 'moment';
import useStyles from './styles';

const columns = [
  { id: 'link', label: 'Link' },
  {
    id: 'date',
    label: 'Date',
    format: value => moment(value * 1000).format('D MMM YYYY'),
    minWidth: 110,
  },
  {
    id: 'time',
    label: 'Time',
    format: value => moment(value * 1000).format('h:mm:ss a'),
    minWidth: 110,
  },
  { id: 'name', label: 'Name' },
  { id: 'company', label: 'Company' },
  { id: 'contact', label: 'Contact Number' },
  { id: 'email', label: 'Email' },
];

function CampaignsList() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();
  const { campaigns, loading } = useSelector(state => state.campaignsList);
  const rows = campaigns.map(({ id, ...more }) => ({
    link: id,
    time: more.date,
    ...more,
  }));

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <MainLoader show={loading} />
      <Grow in={!loading} timeout={1000}>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.link}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === 'link' ? (
                                <IconButton
                                  aria-label="Visit campaign link"
                                  size="small"
                                  onClick={() =>
                                    window.open(
                                      `${window.location.origin}/campaign/${value}`,
                                      '_blank',
                                      'noopener',
                                    )
                                  }
                                >
                                  <LinkRoundedIcon />
                                </IconButton>
                              ) : column.format && typeof value === 'number' ? (
                                column.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 6, 7, 8, 9, 10, 12, 15, 20, 25, 50, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </Grow>
    </>
  );
}

export default CampaignsList;
