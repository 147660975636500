import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

function DeleteDialog({ children, open, setOpen, handleDelete }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.dialogText}>You are about to delete</Typography>
        <div className={classes.content}>{children}</div>
        <Typography className={classes.dialogText}>Do you wish to continue?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleDelete();
            setOpen(false);
          }}
          variant="contained"
          color="primary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default memo(DeleteDialog);
