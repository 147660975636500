import React, { memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { formatFileName, renderIcon } from 'utils/helpers/formatFile';
import CircularProgress from '@material-ui/core/CircularProgress';
import { cancelUploads } from 'redux/features/adForm.slice';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles';

const UploadSnackbar = React.forwardRef(({ id }, ref) => {
  const [expanded, setExpanded] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const classes = useStyles();
  const adForm = useSelector(state => state.adForm);
  const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });

  const handleDismiss = () => {
    adForm.uploadTasks.some(task => task.snapshot.state === 'running')
      ? setDialogOpen(true)
      : closeSnackbar(id);
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="cancel-upload"
        aria-describedby="cancel-upload"
        className={classes.dialog}
      >
        <DialogTitle id="cancel-upload">Cancel Upload?</DialogTitle>
        <DialogContent id="cancel-upload">
          <Typography gutterBottom>
            Your upload is not complete. Would you like to cancel the upload?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary" autoFocus>
            Continue Upload
          </Button>
          <Button
            onClick={() => {
              dispatch(cancelUploads());
              closeSnackbar(id);
            }}
            color="primary"
            variant="contained"
          >
            Cancel upload
          </Button>
        </DialogActions>
      </Dialog>
      <Card className={classes.card} ref={ref}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Typography className={classes.typography}>
            Uploading {adForm.attachedFilesInfo.length} files
          </Typography>
          <div className={classes.icons}>
            <IconButton
              aria-label="Show more"
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={() => setExpanded(!expanded)}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton className={classes.expand} onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List className={classes.list}>
            {adForm.attachedFilesInfo.map((f, i, arr) => {
              return (
                <ListItem divider={i < arr.length - 1} key={i}>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <Icon>{renderIcon(f.type)}</Icon>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={formatFileName(f.name, up600 ? 28 : 22)}
                    // secondary={formatFileSize(f.size)}
                    className={classes.listItemText}
                  />
                  <div className={classes.progressContainer}>
                    {f.progress < 100 ? (
                      <>
                        <CircularProgress
                          className={classes.progress}
                          variant="static"
                          value={f.progress}
                        />
                        <CircularProgress
                          className={clsx(classes.progressBG, classes.progress)}
                          variant="static"
                          value={100}
                        />
                      </>
                    ) : (
                      <CheckCircleIcon className={classes.checkIcon} />
                    )}
                  </div>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </Card>
    </>
  );
});

export default memo(UploadSnackbar);
