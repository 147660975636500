import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  dialogContent: {
    padding: '8px 32px !important',
  },
  dialogText: {
    margin: spacing(2, 0),
    fontSize: '1.45rem',
    fontWeight: 500,
  },
  content: {
    margin: spacing(3, 0),
  },
}));
