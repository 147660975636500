import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints }) => ({
  content: {
    padding: spacing(2, 1),
    [breakpoints.up('sm')]: {
      padding: spacing(4, 3),
    },
  },
  title: {
    fontWeight: 400,
    marginBottom: spacing(2),
    [breakpoints.up('sm')]: {
      marginBottom: spacing(4),
    },
  },
  selectAll: {
    display: 'block',
    marginLeft: 'auto',
  },
}));
