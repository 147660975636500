import React, { memo, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toggleSelect } from 'redux/features/campaign.slice';
import Icon from '@material-ui/core/Icon';
import CheckButton from 'components/CheckButton/CheckButton';
import useStyles from './styles';

function CardItem({ title, icon, selected, id, wizardItem }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(false);

  const isFirstTwoPanels = wizardItem === 'Campaign Objective' || wizardItem === 'Media Type';

  return (
    <Card
      raised={hovered}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={classes.cardItem}
      onClick={() => dispatch(toggleSelect(false, wizardItem, id))}
    >
      <ButtonBase className={classes.cardButton} focusRipple>
        <CardContent className={classes.cardContent}>
          <div className={classes.cardIcon}>
            <Icon>{icon}</Icon>
          </div>
          <Typography className={classes.cardTitle}>{title}</Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <CheckButton radio={isFirstTwoPanels} selected={selected} />
        </CardActions>
      </ButtonBase>
    </Card>
  );
}

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  wizardItem: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default memo(CardItem);
