// ads played per day and visitors per day are used for the equation that calculates the network reach
export const adsPlayedPerDay = 120;
export const visitorsPerDay = 150;

export const steps = [
  'Campaign Objective',
  'Media Type',
  'Display Networks',
  'Display Locations',
  'Display Brands',
];

export const isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream);

export const formFields = [
  { item: 'name', label: 'Name', type: 'text' },
  { item: 'email', label: 'Email', type: 'email' },
  { item: 'contact', label: 'Contact Number', type: 'number' },
  { item: 'company', label: 'Company', type: 'text' },
];
