import React, { memo } from 'react';
import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useSelector } from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import { steps } from 'config/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grow from '@material-ui/core/Grow';
import useStyles from './styles';

function Stepper() {
  const classes = useStyles();
  const activeStep = useSelector(state => state.activeStep);
  const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });

  const showFirstLabel = up600 ? true : activeStep < 3;
  const showLastLabel = up600 ? true : activeStep >= 3;

  return (
    <Collapse in={activeStep < 6} timeout={400}>
      <MuiStepper className={classes.root} activeStep={activeStep} alternativeLabel>
        <Grow mountOnEnter unmountOnExit in={showFirstLabel} timeout={700}>
          <Step>
            <StepLabel>{steps[1]}</StepLabel>
          </Step>
        </Grow>

        {steps.slice(1).map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}

        <Grow mountOnEnter unmountOnExit in={showLastLabel} timeout={700}>
          <Step>
            <StepLabel>Upload Ad</StepLabel>
          </Step>
        </Grow>
      </MuiStepper>
    </Collapse>
  );
}

export default memo(Stepper);
