import React, { Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useSelector } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import PeopleIcon from '@material-ui/icons/People';
import LoopIcon from '@material-ui/icons/Loop';
import TvIcon from '@material-ui/icons/Tv';
import CountUp from 'react-countup';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './styles';

const networkReachItems = [
  {
    title: 'Estimated Number of Visitors',
    miniTitle: 'Visitors',
    description:
      'Estimated number of people who will visit the selected locations during a period of one month.',
    icon: <PeopleIcon />,
    countText: ' visitors',
  },
  {
    title: 'Estimated Ad Loops',
    miniTitle: 'Ad Loops',
    description: 'Estimated number of play loops for a 30-seconds Ad on the network.',
    icon: <LoopIcon />,
    countText: ' loops',
  },
  {
    title: 'Number of Screens',
    miniTitle: 'Screens',
    description:
      'The total number of screens which will be playing your ad based on your selected locations.',
    icon: <TvIcon />,
    countText: ' screens',
  },
];

function NetworkReach() {
  const up600 = useMediaQuery('(min-width:600px)');
  const drawerOpen = useSelector(state => state.drawerOpen);
  const networkReach = useSelector(state => state.networkReach);
  const classes = useStyles({ drawerOpen });

  const renderCount = ({ start, end }) => <CountUp start={start} end={end} separator="," />;

  return (
    <div className={classes.root}>
      {networkReachItems.map((item, i, arr) => {
        return drawerOpen && up600 ? (
          <Fade key={item.title} in={drawerOpen} timeout={700}>
            <Paper className={i === 1 ? classes.paper : undefined}>
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <Icon>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText className={classes.title} primary={item.title} />
              </ListItem>
              <Divider variant="middle" />
              <ListItem>
                <ListItemText secondary={item.description} />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  className={classes.count}
                  primary={
                    <Typography component="span">
                      <Typography className={classes.countNum} component="span">
                        {renderCount(networkReach.items[i])}
                      </Typography>
                      {item.countText}
                    </Typography>
                  }
                />
              </ListItem>
            </Paper>
          </Fade>
        ) : (
          <Fragment key={item.title}>
            <ListItem className={classes.item}>
              <div className={classes.textContainer}>
                {item.icon}
                <ListItemText className={classes.text} primary={item.miniTitle} />
              </div>
              <ListItemText
                className={classes.text}
                primary={
                  <Typography variant="h6" component="span">
                    {renderCount(networkReach.items[i])}
                  </Typography>
                }
              />
            </ListItem>
            {i < arr.length - 1 && <Divider orientation={up600 ? 'horizontal' : 'vertical'} />}
          </Fragment>
        );
      })}
    </div>
  );
}

export default NetworkReach;
