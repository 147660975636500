import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints }) => ({
  cardItem: {
    margin: spacing(1),
    minWidth: spacing(10.5),
    [breakpoints.up('sm')]: {
      minWidth: spacing(12),
    },
    [breakpoints.up('lg')]: {
      minWidth: spacing(14),
    },
  },
  cardButton: {
    display: 'initial',
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: spacing(2, 1.5, 1),
    [breakpoints.up('sm')]: {
      padding: spacing(2, 1.5, 1),
    },
  },
  cardIcon: {
    '& span': {
      fontSize: '3.5rem',
    },
    marginBottom: spacing(0.5),
    [breakpoints.up('lg')]: {
      marginBottom: spacing(0.75),
    },
  },
  cardTitle: {},
  cardActions: {
    justifyContent: 'center',
    padding: spacing(0.5, 1, 1.5),
    [breakpoints.up('sm')]: {
      padding: spacing(1, 1, 2),
    },
  },
}));
