import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import API from 'utils/api';
import firebase from 'config/firebase';
import snackbar from 'utils/snackbar';
import rootReducer from './features';

const campaignsRef = firebase.firestore().collection('campaigns');
const storageRef = firebase.storage().ref().child('campaigns');

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk.withExtraArgument({ API, campaignsRef, storageRef, firebase, snackbar })],
});

export default store;
