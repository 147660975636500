import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { nextStep, previousStep } from 'redux/features/activeStep.slice';
import Button from '@material-ui/core/Button';
import ReactToPrint from 'react-to-print';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PrintIcon from '@material-ui/icons/Print';
import ShareIcon from '@material-ui/icons/Share';
import { submitCampaign } from 'redux/features/campaign.slice';
import { useHistory } from 'react-router-dom';
import SharePopover from 'components/SharePopover/SharePopover';
import { formFields, steps } from 'config/constants';

import { useSnackbar } from 'notistack';
import useStyles from './styles';

function StepperActions({ toPrintRef, slug }) {
  const dispatch = useDispatch();
  const activeStep = useSelector(state => state.activeStep);
  const campaign = useSelector(state => state.campaign);
  const adForm = useSelector(state => state.adForm);
  const anUploadIsRunning = adForm.uploadTasks.some(task => task.snapshot.state === 'running');
  const classes = useStyles({ summary: activeStep === 6, anUploadIsRunning });
  const history = useHistory();
  const { closeSnackbar } = useSnackbar();

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isSubmitHidden, setIsSubmitHidden] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const isNextDisabled = () => {
    if (activeStep < steps.length) {
      return !campaign.items[steps[activeStep]].some(item => item.selected === true);
    }
    if (activeStep === 5) {
      return formFields.some(
        f => adForm.validation[f.item].length > 0 || adForm.fields[f.item].length === 0,
      );
    }
    return false;
  };

  const getLink = (newCampaignId = slug) => `${window.location.origin}/campaign/${newCampaignId}`;

  const copyToClipboard = toBeCopied => {
    navigator.clipboard.writeText(toBeCopied);
  };

  const snackbarAction = id => () => (
    <Button
      onClick={() => {
        copyToClipboard(getLink(id));
        closeSnackbar();
      }}
    >
      Copy Link
    </Button>
  );

  return (
    <>
      <SharePopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        copyToClipboard={copyToClipboard}
        link={getLink()}
      />
      <div className={classes.root}>
        <Button
          size="large"
          disabled={activeStep < 1}
          onClick={() => {
            dispatch(previousStep());
            setIsSubmitHidden(false);
            setIsSubmitDisabled(false);
          }}
          className={classes.backButton}
          color="primary"
        >
          {activeStep < 6 ? 'Back' : 'Edit Campaign'}
        </Button>

        {activeStep < 6 && (
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={isNextDisabled()}
            onClick={() => dispatch(nextStep())}
          >
            {activeStep < 5 ? 'Next' : 'View Summary'}
          </Button>
        )}
        {activeStep === 6 && (
          <>
            <ReactToPrint
              trigger={() => (
                <Button
                  size="large"
                  className={classes.printButton}
                  startIcon={<PrintIcon />}
                  variant="contained"
                  color="primary"
                >
                  Print
                </Button>
              )}
              content={() => toPrintRef.current}
            />
            <div className={classes.uploadBtnContainer}>
              {!!isSubmitHidden && !!slug ? (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={evt => setAnchorEl(evt.currentTarget)}
                  startIcon={<ShareIcon />}
                >
                  Share
                </Button>
              ) : (
                <Button
                  size="large"
                  disabled={anUploadIsRunning || !adForm.attachedFiles.length}
                  onClick={() => {
                    !isSubmitDisabled && dispatch(submitCampaign(slug, snackbarAction, history));
                    setIsSubmitHidden(true);
                    setIsSubmitDisabled(true);
                  }}
                  variant="contained"
                  color="primary"
                  startIcon={<CloudUploadIcon />}
                >
                  Submit Ad
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default StepperActions;
