import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette }) => ({
  dialog: {
    width: '75vw',
    maxWidth: '800px !important',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dragDropContainer: {
    marginBottom: spacing(2),
  },
  dropzone: {
    cursor: 'pointer',
    marginBottom: spacing(2),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: spacing(3, 2, 3.5),
    borderWidth: 2,
    borderRadius: 2,
    borderColor: props =>
      props.isDragAccept
        ? palette.primary.light
        : props.isDragReject
        ? palette.error.main
        : props.isDragActive
        ? palette.secondary.main
        : palette.divider,
    borderStyle: 'dashed',
    backgroundColor: palette.grey[100],
    color: props => (props.isDragReject ? palette.error.main : palette.grey[500]),
    outline: 'none',
    transition: 'border .4s ease-in-out',
  },
  dropzoneText: {
    marginTop: spacing(1),
    fontSize: '1.5rem',
  },
}));
