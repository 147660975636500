import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckButton from 'components/CheckButton/CheckButton';
import TvIcon from '@material-ui/icons/Tv';
import { useDispatch } from 'react-redux';
import { toggleSelect } from 'redux/features/campaign.slice';
import SelectedScreens from 'components/SelectedScreens/SelectedScreens';
import useStyles from './styles';

const ListItem = ({ item, wizardItem }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const listItemTextProps =
    wizardItem === 'Display Locations'
      ? {
          primary: item.city,
          secondary: (
            <span className={classes.screens}>
              <span>{item.screens}</span>
              <span className={classes.multiply}>x</span>
              <TvIcon />
            </span>
          ),
        }
      : {
          primary: item.brand,
          secondary: (
            <span className={classes.secondary}>
              {`${item.area ? item.area : item.province}, ${item.city}, ${item.branch}`}
            </span>
          ),
        };

  return (
    <MuiListItem onClick={() => dispatch(toggleSelect(false, wizardItem, item.id))} button>
      <ListItemText className={classes.listItemText} {...listItemTextProps} />
      {wizardItem === 'Display Brands' && (
        <SelectedScreens
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          selection={item.selectedScreens}
          totalScreens={item.screens}
          disabled={!item.selected}
          onSelection={option => {
            dispatch(toggleSelect(false, 'Display Brands', item.id, option));
          }}
        />
      )}
      <div className={classes.selectButton}>
        <CheckButton selected={item.selected} />
      </div>
    </MuiListItem>
  );
};

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  wizardItem: PropTypes.string,
};

export default memo(ListItem);
