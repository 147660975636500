import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette }) => ({
  popover: {
    maxWidth: '600px !important',
    width: 'calc(100% - 48px)',
    padding: spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& button,& input': {
      height: spacing(4.5),
    },
  },
  textField: {
    flex: 1,
    marginRight: '8px !important',
    '& input': {
      boxSizing: 'border-box',
      padding: spacing(1, 1.5),
      fontWeight: 300,
      color: palette.primary.main,
    },
    '& input::selection': {
      backgroundColor: palette.primary.main,
      color: '#fff',
    },
    '& fieldset, & fieldset:hover': {
      borderColor: `${palette.primary.main} !important`,
    },
  },
}));
