import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Grow from '@material-ui/core/Grow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { deleteFile } from 'redux/features/adForm.slice';
import DeleteDialog from 'components/DeleteDialog/DeleteDialog';
import { formatFileName, formatFileSize, renderIcon } from 'utils/helpers/formatFile';
import useStyles from './styles';

function FileChip({ file, handleDelete }) {
  const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const chipProps = {
    icon: <Icon className="chipIcon">{renderIcon(file.type)}</Icon>,
    classes: { icon: classes.chipIcon, label: classes.chipLabel },
    label: (
      <>
        <span>{formatFileName(file.name, up600 ? 28 : 22)}</span>
        <span className={classes.fileSize}>{formatFileSize(file.size)}</span>
      </>
    ),
    ...(file.downloadURL
      ? {
          clickable: true,
          component: 'a',
          href: file.downloadURL,
          target: '_blank',
          rel: 'noopener',
          onDelete: !handleDelete
            ? null
            : e => {
                e.stopPropagation();
                e.preventDefault();
                setDialogOpen(true);
              },
        }
      : {
          onDelete: handleDelete,
        }),
  };
  const toolTipTitle = file.updated
    ? `Uploaded on ${moment(file.updated).format('MMMM Do YYYY, h:mm:ss a')}`
    : '';

  return (
    <>
      <DeleteDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        handleDelete={() => dispatch(deleteFile(file))}
      >
        <Chip {...chipProps} {...{ onDelete: null }} />
      </DeleteDialog>
      <Tooltip
        placement="top"
        arrow
        title={toolTipTitle}
        classes={{
          tooltipArrow: classes.tooltip,
          arrow: classes.arrow,
        }}
      >
        <Grow in timeout={600}>
          <Chip {...chipProps} />
        </Grow>
      </Tooltip>
    </>
  );
}

FileChip.propTypes = {
  file: PropTypes.object.isRequired,
  handleDelete: PropTypes.func,
};

export default memo(FileChip);
